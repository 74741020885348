.translation {
  max-width: 100%;
  overflow: hidden;
  background: linear-gradient(rgba(56, 52, 41, 0.6), rgba(56, 52, 41, 0.9)), url(/src/assets/pic2.webp);
  object-fit: cover;
  background-position: center;
  background-size: cover;
  background-attachment: fixed;
}

.translation_section-container {
  width: 100%;
  padding: 0 5%;
  display: flex;
  flex-direction: column;
  padding-bottom: 2rem;
}

.translation_one {
  width: 100%;
}

.translation_two {
  width: 100%;
}

.translation_page_title h2 {
  color: #ffca7a;
  position: relative;
  font-size: 22px;
  padding: 2rem 0 0 0;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.translation_page_title hr {
  width: 110px; /* Line width */
  border: none;
  height: 3px; /* Thickness of the line */
  background-color: #b7312a; /* Line color */
  margin-top: 10px; /* Center the line and add space around it */
  margin-bottom: 20px;
}

.translation_page_title p {
  width: 100%;
  color: #ffca7a;
  font-size: 0.8rem;
  padding: 1rem 0 2rem 0;
  line-height: 20px;
}

.translation_second_paragraph {
  width: 100%;
  padding: 0 0 2rem 0;
  color: #ffca7a;
  font-size: 0.8rem;
  line-height: 20px;
}

.steps-container {
  max-width: 100%;
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  justify-items: center;
  padding-top: 2rem;
}

.step-item {
  width: 100%;
  display: flex;
  flex-direction: column;
  text-align: start;
  justify-items: flex-start;
}

/* Style for smooth content toggle */
.step-content {
  max-height: 0;
  overflow: hidden;
  transition: max-height 0.4s ease-in-out, opacity 0.4s ease-in-out;
  opacity: 0;
}

.step-content.open {
  max-height: 200px; /* Set max-height based on expected content size */
  opacity: 1;
}

.icon-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  background-color: #b7312a;
}

.icon_and_title_container {
  display: flex;
  flex-direction: row;
  gap: 0.5rem;
  padding: 1rem;
  align-items: center;
}

.icon {
  color: #ffca7a;
}

.step-title {
  font-size: 0.875rem;
  color: #ffca7a;
  font-family: 'subHeaderFont', sans-serif;
  font-weight: 500;
}

.expand_icons .expand,
.expand_icons .close {
  color: #ffca7a;
  font-size: 20px;
  padding-right: 1rem;
}

/* Smooth rotate transition for icons */
.expand_icons_button {
  background: none;
  border: none;
  cursor: pointer;
  transition: transform 0.3s ease;
}

.expand_icons_button.expand {
  transform: rotate(0deg);
}

.expand_icons_button.close {
  transform: rotate(180deg);
  padding-left: 1rem;
}

.step-description {
  max-width: 100%;
  max-height: none;
  font-size: 0.75rem;
  color: #1b2a1f; /* darkJungleGreen */
  line-height: 1rem;
  font-family: 'paragraphTextFont', sans-serif;
  padding: 1rem;
  background-color: #ffca7a;
}

@media screen and (min-width: 576px) {
  .translation_section-container {
    flex-direction: column;
  }

  .translation_one {
    width: 100%;
  }

  .translation_two {
    width: 100%;
  }

  .translation_page_title p {
    padding: 1rem 2rem 2rem 4rem;
  }
}

@media screen and (min-width: 768px) {
  .translation_page_title p {
    padding: 1rem 0 2rem 4rem;
  }
}

@media screen and (min-width: 992px) {
  .translation_section-container {
    flex-direction: row;
  }

  .translation_page_title p {
    padding: 1rem 2rem 2rem 4rem;
  }

  .translation_second_paragraph {
    padding: 0 2rem 2rem 0;
  }
}
