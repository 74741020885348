@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
}

.button {
  padding: 12px 24px;
  cursor: pointer;
}

@supports (-webkit-text-stroke: 1px rgba(225, 225, 225, 0.5)) {
  .stroke_text {
    -webkit-text-stroke: 1px rgb(255, 243, 197);
    -webkit-text-fill-color: transparent;
  }
}

::-webkit-scrollbar {
  display: none;
}

@media screen and (max-width: 992px) {
  .container {
    padding: 0 5%;
  }
}

@media screen and (max-width: 768px) {
  .container {
    padding: 0 5%;
  }
}
