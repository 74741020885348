.card_wrapper {
  width: 100%;
  display: flex;
  position: relative;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 2rem;
  padding: 2rem 5%;
  background-color: #d3ccb8e6;
  z-index: 5;
}

.items_card_container {
  display: flex;
  flex-direction: row;
}

.items_card_wrapper {
  width: 300px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  background-color: #b7312a;
}

.image_wrapper_one {
  background: url(/src//assets//image1.webp);
}

.image_wrapper_two {
  background: url(/src//assets//image5.JPG);
}

.image_wrapper_three {
  background: url(/src//assets//christopher-gower-vjMgqUkS8q8-unsplash.jpg);
}

.image_card_item {
  width: 300px;
  height: 20vh;
  object-fit: contain;
  background-position: center;
  background-size: cover;
}

.card_description_wrapper {
  padding: 1rem;
}

.card_description_wrapper h2 {
  font-size: 14px;
  color: #ffca7a;
  padding-bottom: 1rem;
}

.card_description_wrapper p {
  font-size: 0.7rem;
  color: #ffca7a;
  padding-bottom: 1rem;
}

.see-more {
  text-decoration: none;
  color: #b7312a;
  font-size: 12px;
  font-weight: 600;
  padding: 8px 16px;
  background-color: #ffca7a;
  border: 1px solid #b7312a;
  transition: all 0.5s ease;
  text-transform: uppercase;
}

.see-more:hover {
  color: rgb(134, 113, 73);
  background-color: rgba(255, 255, 255, 0.5);
}

@media screen and (min-width: 576px) {
  .items_card_wrapper {
    width: 350px;
  }

  .image_card_item {
    width: 350px;
    height: 20vh;
    object-fit: contain;
    background-position: center;
    background-size: cover;
  }
}

@media screen and (min-width: 768px) {
  .items_card_wrapper {
    width: 650px;
  }

  .image_card_item {
    width: 650px;
    height: 30vh;
    object-fit: contain;
    background-position: center;
    background-size: cover;
  }
}

@media (min-width: 992px) {
  .items_card_wrapper {
    width: 350px;
  }

  .image_card_item {
    width: 350px;
    height: 15vh;
    object-fit: contain;
    background-position: center;
    background-size: cover;
  }

  .card_wrapper {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    position: absolute;
    top: 114%;
    padding: 0 5%;
    left: 50%;
    transform: translate(-50%, -50%);
    background: none;
  }
}
