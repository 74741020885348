/* contact.css */

.contact {
  width: 100%;
  padding: 2rem 5%;
  background: linear-gradient(rgba(56, 52, 41, 0.6), rgba(56, 52, 41, 0.9)), url(/src/assets/charles-forerunner-3fPXt37X6UQ-unsplash.webp);
  object-fit: cover;
  background-position: center;
  background-size: cover;
  background-attachment: fixed;
  overflow: hidden;
}

.form-container {
  padding: 2rem 5%;
}

.contact_section > .form-container,
.toggle-buttons {
  background: linear-gradient(rgba(56, 52, 41, 0.6), rgba(56, 52, 41, 0.9)), url(/src/assets/charles-forerunner-3fPXt37X6UQ-unsplash.webp);
  object-fit: cover;
  background-position: center;
  background-size: cover;
  background-attachment: fixed;
  overflow: hidden;
}

.contact_title_main_container .overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 80vh;
  background: linear-gradient(-25deg, rgba(53, 44, 20, 0.7) 100%, rgba(37, 31, 10, 0.9) 100%);
  z-index: 0;
}

.contact_title_main_container {
  width: 100%;
  height: 80vh;
  padding-left: 5%;
  padding-right: 5%;
  padding-top: 30%;
  overflow: hidden;
}

.toggle-buttons {
  display: flex;
  justify-content: center;
  margin: 0 0  1rem 0;
  z-index: 2;
}

.contact_section > .toggle-buttons {
  padding-top: 2rem;
  margin: 0;
}

.contact_section > .form-container {
  padding-top: 2rem;
}

.contact-form-section {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.text-section,
.form-section {
  width: 100%;
}

.toggle-btn {
  padding: 0.5rem 1rem;
  margin: 0 0.5rem;
  font-size: 0.8rem;
  cursor: pointer;
  border: none;
  background: none;
  color: #ffca7a;
  text-transform: uppercase;
}

.toggle-btn.active {
  background-color: #ffca7a;
  color: #1a3c34;
}

.text_section_wrapper h2 {
  color: #ffca7a;
  position: relative;
  font-size: 22px;
  padding: 2rem 0 0 0;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.text_section_wrapper hr {
  width: 110px; /* Line width */
  border: none;
  height: 3px; /* Thickness of the line */
  background-color: #b7312a; /* Line color */
  margin-top: 10px; /* Center the line and add space around it */
  margin-bottom: 20px;
}

.text_section_wrapper p {
  width: 100%;
  color: #ffca7a;
  font-size: 0.8rem;
  padding: 1rem 0 2rem 0;
  line-height: 20px;
}

.contact_second_paragraph {
  width: 100%;
  padding: 0 0 2rem 0;
  color: #ffca7a;
  font-size: 0.8rem;
  line-height: 20px;
}

.contact-form {
  width: 100%;
  padding: 1rem;
  margin-top: 1rem;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  text-align: center;
  backdrop-filter: blur(10px);
  -webkit-backdrop-filter: blur(10px);
  box-shadow: -15px 17px 17px rgba(10, 10, 10, 0.25);
}

.contact_details {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  gap: 1rem;
}

.input {
  width: 100%;
  font-size: 0.8rem;
  color: #ffca7a;
  padding: 10px;
  background: none;
  border: 1px solid  #ffca7a;
  outline: none;
}

::placeholder {
  font-size: 0.8rem;
  color: #ffca7a;
}

.contact-form textarea {
  resize: none;
}

.contact-form button {
  padding: 10px 30px;
  background: none;
  color: #ffca7a;
  border: 1px solid #ffca7a;
  cursor: pointer;
  font-size: 0.8rem;
  text-transform: uppercase;
  margin-top: 2rem;
}

.contact-form button:hover {
  background-color: #ffca7a;
  color: #1a3c34;
}

@media screen and (min-width: 576px) {
  .contact_title_main_container .overlay {
    height: 65vh;
  }

  .contact_title_main_container {
    height: 65vh;
    padding-top: 20%;
  }

  .text_section_wrapper p {
    padding: 1rem 2rem 2rem 4rem;
  }
}

@media screen and (min-width: 768px) {
  .contact_title_main_container {
    padding-top: 15%;
  }

  .text_section_wrapper p {
    padding: 1rem 0 2rem 4rem;
  }

  .contact_details {
    flex-direction: row;
  }
}

@media screen and (min-width: 992px) {
  .contact-form-section {
    flex-direction: row;
  }

  .contact-form {
    padding: 2rem;
    margin-top: 2rem;
    margin-bottom: 2rem;
  }

  .text_section_wrapper p {
    padding: 1rem 2rem 2rem 4rem;
  }

  .contact_second_paragraph {
    padding: 0 2rem 2rem 0;
  }
}
