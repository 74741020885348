footer {
  width: 100%;
  padding: 16px 5%;
  background: linear-gradient(-25deg, rgb(53, 44, 20) 0%, rgb(37, 31, 10) 100%);
  box-shadow: rgba(0, 0, 0, 0.17) 0 -23px 25px 0 inset, rgba(0, 0, 0, 0.15) 0 -36px 30px 0 inset, rgba(0, 0, 0, 0.1) 0 -79px 40px 0 inset, rgba(0, 0, 0, 0.06) 0 2px 1px, rgba(0, 0, 0, 0.09) 0 4px 2px, rgba(0, 0, 0, 0.09) 0 8px 4px, rgba(0, 0, 0, 0.09) 0 16px 8px, rgba(0, 0, 0, 0.09) 0 32px 16px;
}

.footer_container {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.footer_link {
  color: #ffca7a;
  cursor: pointer;
}

.footer_link:hover {
  color: rgba(255, 202, 122, 0.6);
}

.footer_logo {
  cursor: pointer;
  width: 70px;
}

.footer_container ul {
  display: flex;
  align-items: center;
  gap: 2rem;
  margin-bottom: 2rem;
}

.footer_socials_wrapper {
  display: flex;
  gap: 3rem;
  margin-bottom: 3rem;
}

.footer_socials_wrapper .socials_icon {
  font-size: 20px;
}

.footer-content {
  width: 100%;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  align-items: flex-start;
}

.brand-section {
  display: flex;
  flex-direction: column;
  align-items: start;
}

.brand-description {
  max-width: 75%;
  padding-bottom: 20px;
  padding-top: 3rem;
  font-size: 0.7rem;
  color: #d2c79f; /* foggyGrey */
  line-height: 1rem;
  text-align: start;
}

.social-links {
  display: flex;
  justify-content: center;
  gap: 40px;
}

.links-section {
  width: 100%;
  padding-top: 30px;
}

.links-title {
  font-size: 0.8rem;
  padding-bottom: 40px;
  color: #ffca7a; /* foggyGrey */
  font-weight: normal;
  text-align: center;
  text-transform: uppercase;
}

.links-container {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
}

.links-list {
  list-style: none;
  padding: 0;
  margin: 0;
}

.link {
  color: #d2c79f;
  text-decoration: none;
  padding: 6px 12px;
  font-family: 'Montserrat', sans-serif;
  font-size: 0.7rem;
  line-height: 23px;
  display: block;
}

.newsletter-section {
  width: 100%;
  margin-top: 30px;
}

.newsletter-title {
  font-size: 0.8rem;
  padding-bottom: 40px;
  color: #ffca7a;
  font-weight: normal;
  text-align: center;
  text-transform: uppercase;
}

.newsletter-form {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 1rem 0;
}

.form {
  width: 100%;
  display: flex;
  align-items: center;
}

.form input {
  color: #ffca7a;
}

.email-input {
  width: 100%;
  padding: 6.5px 8px;
  border: 1px solid #ffca7a;
  background: transparent;
  font-size: 0.7rem;
  outline: none;
}

.subscribe-button {
  color: #1a3c34;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #ffca7a;
  border: none;
  padding: 6px 4px;
  cursor: pointer;
  font-size: 0.7rem;
  gap: 0.2rem;
}

.send-icon {
  color: #1a3c34;
  width: 1rem;
  height: 1rem;
  transform: rotate(-45deg);
}

.message {
  color: #f0f0f0; /* foggyGrey */
  font-size: 0.5rem;
  display: block;
  padding-top: 10px;
}

.newsletter-description {
  max-width: 100%;
  padding-top: 5px;
  font-size: 0.7rem;
  color: #d2c79f;
  line-height: 1rem;
  text-align: center;
}

.footer-bottom {
  padding-top: 2rem;
}

.divider-line {
  border: none;
  height: 1px;
  background-color: #d2c79f;
  margin-top: 10px;
  margin-bottom: 10px;
}

.footer-bottom-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
}

.terms-text,
.copyrights {
  font-size: 0.7rem;
  color: #d2c79f;
}

@media screen and (min-width: 768px) {
  .brand-section {
    width: 30%;
    display: flex;
    flex-direction: column;
    align-items: start;
  }

  .links-section {
    width: 30%;
  }

  .newsletter-section {
    width: 30%;
  }

  .footer-content {
    flex-direction: row;
    align-items: flex-start;
    gap: 10%;
  }

  .footer_logo {
    width: 50px;
  }

  .brand-description {
    padding-top: 2rem;
    color: #d2c79f;
  }

  .footer-bottom-content {
    flex-direction: row;
  }

  .terms-text,
  .copyrights {
    text-align: center;
  }
}

@media screen and (min-width: 992px) {
  .links-container {
    justify-content: space-around;
  }
}
