.price-calculator {
  width: 100%;
  padding: 1rem 10px;
  text-align: center;
  backdrop-filter: blur(10px);
  -webkit-backdrop-filter: blur(10px);
  box-shadow: rgba(0, 0, 0, 0.2) 0 60px 40px -7px;
}

.price-calculator h2 {
  color: #ffca7a;
  text-align: center;
  font-size: 22px;
  margin-bottom: 1rem;
}

.price-calculator select {
  color: #ffca7a;
  background: none;
}

.file_upload,
p {
  color: #ffca7a;
  font-size: 0.8rem;
}

.file_upload::placeholder {
  background: none;
}

.price-calculator input {
  color: #ffca7a;
  background: none;
  font-size: 0.8rem;
}

.price-calculator label {
  display: block;
  margin-bottom: 5px;
}

.hidden_label {
  font-size: 12px;
  color: #ffca7a;
  text-align: left;
}

/* .hidden_label .file_upload {
  visibility: visible;
}

.hidden_label .email_input {
  visibility: visible;
} */

.price-calculator select,
.price-calculator input {
  width: 100%;
  padding: 10px 8px;
  margin-bottom: 1rem;
  outline: none;
  font-size: 0.8rem;
  border: 1px solid #ffca7a;
}

.price-calculator button {
  padding: 10px 30px;
  background: none;
  color: #ffca7a;
  border: 1px solid #ffca7a;
  cursor: pointer;
  font-size: 10px;
  text-transform: uppercase;
  margin-top: 1rem;
}

.price-calculator button:hover {
  background-color: #ffca7a;
  color: #1a3c34;
}

.price-calculator h3 {
  margin-top: 15px;
  font-size: 1.2rem;
  color: #28a745;
}

@media screen and (min-width: 576px) {
  .price-calculator {
    width: 100%;
  }
}

@media screen and (min-width: 768px) {
  .price-calculator {
    width: 100%;
    padding: 1rem 1rem;
  }
}

@media screen and (min-width: 992px) {
  .price-calculator h2 {
    font-size: 1.5rem;
    margin-bottom: 2rem;
  }

  .price-calculator input {
    margin-bottom: 1.5rem;
  }
}
