.home_scroll_button {
  position: relative;
  display: flex;
  align-items: center;
  z-index: 2;
}

.wheel {
  animation: scroll 2s ease infinite;
}

@keyframes scroll {
  0% {
    transform: translateY(0);
  }

  30% {
    transform: translateY(0.5rem);
  }
}

.home_scroll_name {
  color: #ffca7a;
  font-size: 12px;
  margin-left: 8px;
  margin-right: 2px;
  font-weight: 600;
}

.home_scroll_arrow {
  color: #ffca7a;
  font-size: 1.25rem;
}

@media screen and (max-width: 992px) {
  .home_scroll_button {
    /* margin-left: 8.25rem; */
    display: flex;
    align-items: center;
  }
}

@media screen and (max-width: 768px) {
  .home_scroll_button {
    display: none;
  }
}
