.socials_wrapper {
  position: absolute;
  display: flex;
  gap: 1.5rem;
  top: 92%;
  z-index: 4;
  padding-top: 2rem;
}

.socials_icon {
  color: #ffca7a;
  font-size: 1rem;
  transition-duration: 0.5s;
}

.socials_icon:hover {
  transform: scale(1.3);
}

@keyframes scale {
  0% {
    transform: scale(1.3);
  }

  100% {
    transform: scale(1);
  }
}

/* .socials-1 {
    animation: move_text 1s 0.4s forwards;
  }

  .socials-2 {
    animation: move_text 1s 0.5s forwards;
  }

  .socials-3 {
    animation: move_text 1s 0.6s forwards;
  }

  .socials-5 {
    animation: move_text 1s 0.7s forwards;
  } */

@keyframes move_text {
  0% {
    transform: translateY(40rem) rotateY(-20deg);
  }

  100% {
    transform: translateY(0) rotateY(0);
    opacity: 1;
  }
}

@media screen and (min-width: 768px) {
  .socials_and_scroll {
    padding-bottom: 2rem;
  }

  .socials_wrapper {
    position: relative;
    gap: 3rem;
    padding-top: 0;
  }
}
