.home {
  width: 100%;

  /* height: 100vh; */
  position: relative;
  padding: 0 5%;

  /* overflow: hidden; */
}

.bgVideo {
  width: 100%;
  height: 100vh;
  position: fixed;
  top: 50%;
  left: 50%;
  object-fit: cover;
  transform: translate(-50%, -50%);
  z-index: -1; /* Ensure this is behind other elements */
  pointer-events: painted;
}

.overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(-25deg, rgba(53, 44, 20, 0.7) 0%, rgba(37, 31, 10, 0.9) 100%);
  z-index: 0; /* Ensure this is behind text */
}

.home_wrapper {
  width: 100%;
  position: relative; /* Make sure this is positioned relative for z-index to work */
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  z-index: 1; /* Place this above the overlay */
  padding-top: 32%;
}

.half_one_wrapper {
  width: 100%;
  display: flex;
  flex-direction: column;
}

.half_two_wrapper {
  width: 100%;
  padding-bottom: 4.7rem;
}

.best_ad {
  position: relative;
  font-size: 14px;
  color: #ffca7a;
  background: linear-gradient(-25deg, rgba(53, 44, 20, 0.7) 0%, rgba(37, 31, 10, 0.9) 100%);
  border-radius: 5px;
  width: fit-content;
  padding: 12px 16px;
  text-transform: uppercase;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.best_ad > span:nth-of-type(1) {
  z-index: 2;
  color: #1f1f1f;
  font-style: italic;
  padding-right: 10px;
}

.best_ad > div {
  width: 3rem;
  height: 65%;
  position: absolute;
  background-color: #ffca7a;
  border-radius: 5px;
  left: 8px;
}

.home_title {
  width: 100%;
  font-size: 1.6rem;
  color: #ffca7a;
  font-weight: 900;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding-top: 1rem;
  padding-bottom: 1rem;
  gap: 1rem;
  z-index: 2;
}

h1 {
  width: 100%;
}

.home_subtitle {
  width: 100%;
  color: #b11810;
  font-style: italic;
  font-size: 14px;
  padding: 0.5rem;
  margin-top: 1rem;
  text-align: center;
  background-color: #ffca7a;
}

.home_paragraph {
  width: 100%;
  font-size: 0.7rem;
  color: #d2c79f;
  font-style: italic;
  line-height: 15px;
}

.socials_and_scroll {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.stroke_text {
  font-style: italic;
}

.quotation_button {
  font-size: 10px;
  margin: 1rem 0;
  background: transparent;
  color: #ffca7a;
  padding: 8px 12px;
  border: 1px solid #ffca7a;
  text-transform: uppercase;
}

.quotation_button:hover {
  color: #1a3c34;
  background-color: #ffca7a;
}

@media screen and (min-width: 576px) {
  .home_wrapper {
    padding-top: 7rem;
  }

  .home_title {
    width: 90%;
    font-size: 1.8rem;
  }

  .home_paragraph {
    width: 70%;
    font-size: 0.75rem;
  }
}

@media screen and (min-width: 768px) {
  .home_wrapper {
    padding-top: 20%;
  }

  .home_title {
    width: 100%;
    font-size: 2.5rem;
  }

  .home_paragraph {
    width: 70%;
    font-size: 0.8rem;
    line-height: 20px;
  }
}

@media screen and (min-width: 992px) {
  .home {
    height: 100vh;
  }

  .home_wrapper {
    flex-direction: row;
    padding-top: 14%;
  }

  .half_one_wrapper {
    width: 65%;
  }

  .half_two_wrapper {
    width: 35%;
    padding-top: 5%;
  }

  .best_ad {
    font-size: 18px;
  }

  .best_ad > div {
    width: 3.8rem;
  }

  .home_title {
    font-size: 3.2rem;
  }

  .home_subtitle {
    width: 70%;
    font-size: 18px;
    margin-top: 2rem;
  }

  .home_paragraph {
    width: 50%;
    line-height: 20px;
    padding-bottom: 1rem;
  }

  .socials_and_scroll {
    padding-top: 8%;
  }

  .quotation_button {
    font-size: 0.8rem;
    margin: 1rem 0;
    padding: 12px 16px;
  }
}
