header {
  width: 100%;
  position: fixed;
  display: flex;
  flex-direction: column;
  top: 0;
  left: 0;
  z-index: 10;
  transition: background-color 0.3s ease;
}

header.scrolled {
  background: linear-gradient(-25deg, rgb(53, 44, 20) 0%, rgb(37, 31, 10) 100%);
  box-shadow: rgba(0, 0, 0, 0.17) 0 -23px 25px 0 inset, rgba(0, 0, 0, 0.15) 0 -36px 30px 0 inset, rgba(0, 0, 0, 0.1) 0 -79px 40px 0 inset, rgba(0, 0, 0, 0.06) 0 2px 1px, rgba(0, 0, 0, 0.09) 0 4px 2px, rgba(0, 0, 0, 0.09) 0 8px 4px, rgba(0, 0, 0, 0.09) 0 16px 8px, rgba(0, 0, 0, 0.09) 0 32px 16px;
}

nav {
  height: 4rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 5%;
}

.header_contact_card {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  gap: 2rem;
  padding: 0 5%;
  transition: opacity 0.3s ease;
  background: linear-gradient(-25deg, rgb(53, 44, 20) 0%, rgb(37, 31, 10) 100%);
  box-shadow: rgba(0, 0, 0, 0.17) 0 -23px 25px 0 inset, rgba(0, 0, 0, 0.15) 0 -36px 30px 0 inset, rgba(0, 0, 0, 0.1) 0 -79px 40px 0 inset, rgba(0, 0, 0, 0.06) 0 2px 1px, rgba(0, 0, 0, 0.09) 0 4px 2px, rgba(0, 0, 0, 0.09) 0 8px 4px, rgba(0, 0, 0, 0.09) 0 16px 8px, rgba(0, 0, 0, 0.09) 0 32px 16px;
}

.header_contact_card.hidden {
  opacity: 0;
  visibility: hidden;
}

.contact_card {
  padding-top: 0.3rem;
  padding-bottom: 0.3rem;
  display: flex;
  flex-direction: row;
  justify-items: center;
  gap: 0.5rem;
  cursor: pointer;
}

.header_contact_button {
  color: rgb(255, 202, 122);
  font-size: 10px;
}

.contact_card_icon {
  color: #ffca7a;
  font-size: 12px;
}

.logo img {
  width: 60px;
}

.logo,
.nav_toggle {
  color: #ffca7a;
}

.nav_menu ul {
  display: flex;
  column-gap: 1rem;
}

li {
  list-style: none;
  font-size: 12px;
}

a {
  text-decoration: none;
}

/* Display None */

.nav_icon,
.nav_close,
.nav_toggle,
.visually-hidden {
  display: none;
}

/* >>>>>>>>>>>>> */

.nav_link {
  color: #ffca7a;
  display: flex;
  flex-direction: column;
  align-items: center;
  transition: 0.3s;
  padding: 6px 12px;
}

.active_link,
.nav_link:hover {
  border-top-width: 1px;
  border-bottom-width: 1px;

  /* border-left-style: solid; */
  border-bottom-style: solid;

  /* border-left-color: #ffca7a; */
  border-bottom-color: #ffca7a;
  border-radius: 5px;
}

.uil-bars {
  font-size: 34px;
}

@media screen and (max-width: 768px) {
  nav {
    display: flex;
    justify-content: space-between;
    align-items: center;
    column-gap: 1rem;
    padding: 0 5%;
  }

  .logo img {
    width: 44px;
  }

  .nav_menu {
    position: fixed;
    bottom: -100%;
    left: 0;
    width: 100%;
    padding: 2rem 0 4rem 0;
    background: linear-gradient(-25deg, rgb(53, 44, 20) 0%, rgb(37, 31, 10) 100%);
    box-shadow: rgba(0, 0, 0, 0.17) 0 -23px 25px 0 inset, rgba(0, 0, 0, 0.15) 0 -36px 30px 0 inset, rgba(0, 0, 0, 0.1) 0 -79px 40px 0 inset, rgba(0, 0, 0, 0.06) 0 2px 1px, rgba(0, 0, 0, 0.09) 0 4px 2px, rgba(0, 0, 0, 0.09) 0 8px 4px, rgba(0, 0, 0, 0.09) 0 16px 8px, rgba(0, 0, 0, 0.09) 0 32px 16px;
    border-radius: 5px 5px 0 0;
    transition: 0.3s;
  }

  .header_contact_card {
    gap: 1rem;
  }

  .contact_card_icon {
    font-size: 15px;
  }

  .header_contact_button_icon {
    display: block;
  }

  .header_contact_button {
    display: none;
  }

  .show_menu {
    bottom: 0;
  }

  .nav_menu ul {
    flex-direction: column;
    padding-inline-start: 0;
  }

  .nav_link {
    color: #ffca7a;
    flex-direction: row;
    padding: 20px 1.3rem;
  }

  .nav_icon {
    font-size: 1.2rem;
    padding-right: 1rem;
  }

  .nav_close {
    position: absolute;
    right: 4%;
    bottom: 1.2rem;
    font-size: 1.5rem;
    cursor: pointer;
    color: #ffca7a;
  }

  .nav_toggle {
    font-size: 1.2rem;
    cursor: pointer;
  }

  .nav_icon,
  .nav_close,
  .nav_toggle {
    display: block;
  }

  .active_link,
  .nav_link:hover {
    border: none;
  }
}

@media screen and (max-width: 350px) {
  .nav_menu ul {
    column-gap: 0;
  }

  nav {
    padding: 0 1rem;
  }
}
