
.quotation-form {
  display: flex;
  flex-direction: column;
}

.contact-form {
  display: flex;
  flex-direction: column;
}

.quotation_input_wrapper {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.contact-form input {
  background: none;
  font-size: 0.8rem;
  outline: none;
  padding: 10px;
}

.react-tel-input .form-control {
  background: none;
  outline: none;
  color: #ffca7a;
  font-size: 0.8rem;
  border: 1px solid #ffca7a;
  border-radius: 0;
}

.selected-flag .open {
  background: none;
}

.contact-form select {
  padding: 10px;
  font-size: 0.8rem;
}

.select_country {
  background-color: #ffca7a; /* Light background */
  border: 1px solid #ffca7a; /* Light border */
  border-radius: 5px; /* Rounded corners */
  font-size: 0.8rem; /* Set font size */
  outline: none;
}

.select_country .react-select__menu {
  border-radius: 5px; /* Rounded corners */
  border: 1px solid #ffca7a; /* Border for the dropdown */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Light shadow */
}

/* Style for each option in the dropdown */
.select_country .react-select__option {
  padding: 10px; /* Add padding to the options */
  font-size: 14px; /* Set font size for options */
}

/* Style for the selected option */
.select_country .react-select__option--is-selected {
  background-color: #ffca7a; /* Blue background for selected option */
  color: #ffca7a; /* White text */
}

/* Style for option hover state */
.select_country .react-select__option--is-focused {
  background-color: #e6f7ff; /* Light blue background on hover */
  color: #ffca7a; /* Dark text */
}

.contact-form textarea {
  resize: none;
  font-size: 0.8rem;
}

.phone_input {
  background: none;
}

/* @media screen and (min-width: 576px) {

} */

@media screen and (min-width: 768px) {
  .quotation_input_wrapper {
    flex-direction: row;
  }
}

@media screen and (min-width: 992px) {
  .quotation-form {
    flex-direction: row;
  }

  .contact-form-section {
    flex-direction: row;
  }

  .contact-form {
    padding-top: 2rem;
  }
}
